export default {
  text: 'black',
  text2: '#f1f1f1',
  primary: '#152613',
  secondary: '#af9458',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
