export default {
  primary: {
    backgroundColor: 'transparent',
    color: 'light',
    textTransform: 'uppercase',
    fontFamily: 'subheading',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['1.25rem'],
    padding: '1rem 2.5rem',
    fontWeight: 'bold',
    transition: 'all ease-in-out 0.5s',
    border: 'solid 1px white',
    borderRadius: '0px',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'white'
    }
  },
  secondary: {
    backgroundColor: 'black',
    color: 'light',
    textTransform: 'uppercase',
    fontFamily: 'subheading',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['1.25rem'],
    padding: '1rem 2.5rem',
    fontWeight: 'bold',
    transition: 'all ease-in-out 0.5s',
    border: 'solid 1px white',
    borderRadius: '0px',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'white'
    }
  }
}
